<template>
  <main class="u-container text-center pb-10">
    <h1>Welcome Back</h1>
    <p class="mt-2">
      One moment while we process your extension order.
    </p>
    <AppLoader class="mt-6" />
  </main>
</template>

<script>
import { AppLoader } from '@mgo/ui/lib/components';

export default {
  name: 'Action',
  components: {
    AppLoader,
  },
  async mounted() {
    const { payment } = this.$route.query;
    const success = payment && payment === 'success';
    const extensionOption = sessionStorage.getItem('coacext') || '';

    if (!success || !extensionOption) {
      return this.$router.replace({
        name: 'review',
        query: { emfProcessingFailed: true },
      });
    }

    const { error } = await this.$coac.orderCommit(extensionOption);

    if (error) {
      return this.$router.replace({
        name: 'review',
        query: { emfProcessingFailed: true },
      });
    }

    return this.$router.replace({
      name: 'review',
      query: { extensionSuccess: true },
    });
  },
};
</script>
